import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Loader,
  Button,
  Modal,
  Select,
  TextArea,
} from "semantic-ui-react";
import "./index.css";
import {
  apiCompleteStockTransfer,
  apiCreateStockTransfer,
  apiDeleteStockTransfer,
  apiGetStockTransferDetails,
  apiGetStockTransfers,
} from "../inventoryManagement.api";
import MetaData from "../../../components/MetaData";
import { useDraggable } from "react-use-draggable-scroll";
import _debounce from "lodash/debounce";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import Skeleton from "react-loading-skeleton";
import {
  apiAddStockLocation,
  apiGetStockLocationList,
  apiUpdateStockLocation,
} from "../../Settings/settings.api";
import cartReducer from "../../Cart/cart.reducer";
import searchReducer from "../../Search/search.reducer";
import NumericInput from "react-numeric-input";
import inventoryIcon from "../../../assets/images/pages/inventory.png";
import { transferStatusList, transferStatusListOperator } from "./data";
import { formatDateTime } from "../../../utils/utils";
import productDefaultImage from "../../../assets/images/product_default.png";
import QuickSearch from "../../../components/QuickSearch";

const StockTransfer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [transfersList, setTransfersList] = useState([]);
  const [stocklocationList, setStocklocationList] = useState([]);
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState("In Progress");
  const [productList, setProductList] = useState([]);
  const [stockFormData, setStockFormData] = useState({});
  const [deleteStockTransferOpen, setDeleteStockTransferOpen] = useState(false);
  useState(false);

  const ref = useRef();
  const { events } = useDraggable(ref);

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(formData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "stockLocationTo") {
          const stockLocation = stocklocationList.find(
            (item) => item.id === value
          );
          item.stockLocationToName = stockLocation?.stockLocationName;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setFormData(data[0]);
  }

  const getStockLocationName = (id) => {
    const stockLocation = stocklocationList.find((data) => data?.id === id);
    if (stockLocation) {
      return stockLocation.stockLocationName;
    }
    return "";
  };

  const getStockLocationsData = async () => {
    const result = await apiGetStockLocationList();
    if (result) {
      setStocklocationList(result);
    } else {
      setStocklocationList([]);
    }
  };

  const getTransfersList = async () => {
    setSearchLoading(true);
    const result = await apiGetStockTransfers(status);
    if (result) {
      setTransfersList(result);
      setSearchLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setSearchLoading(false);
    }
  };

  const getTransferDetails = async (id) => {
    if (!id) return false;
    setIsLoading(true);
    const result = await apiGetStockTransferDetails(id);
    if (result) {
      setProductList(result);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteStockTransfer = async () => {
    setIsLoading(true);
    const stockData = await apiDeleteStockTransfer(formData);
    if (stockData) {
      setFormData({});
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Transfer Deleted Successfully
        </div>
      );
      setDeleteStockTransferOpen(false);
      getTransfersList();
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setDeleteStockTransferOpen(false);
      setIsLoading(false);
    }
  };

  const onSaveStockTransfer = async () => {
    setIsLoading(true);
    const result = await apiCreateStockTransfer(formData, productList);
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Transfer saved successfully
        </div>
      );
      setTimeout(function () {
        getTransfersList();
        setFormData({});
        setSelectedId(null);
        setIsLoading(false);
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onCompleteStockTransfer = async () => {
    setIsLoading(true);
    const result = await apiCompleteStockTransfer(formData);
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Transfer completed successfully
        </div>
      );
      setTimeout(function () {
        getTransfersList();
        setFormData({});
        setSelectedId(null);
        setIsLoading(false);
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  useEffect(() => {
    if (selectedId !== 0) {
      getTransferDetails();
    } else {
      setStockFormData({});
    }
  }, [selectedId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getStockLocationsData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getTransfersList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [status]);

  useEffect(() => {
    if (selectedId) {
      getTransferDetails(selectedId);
    }
  }, [selectedId]);

  function handleQuantityChange(value, index) {
    let newState = productList;

    let productsData = newState.map((item, i) => {
      if (i === index) {
        item.moveQty = Number(value);
      }
      return item;
    });

    if (value == 0) {
      productsData.splice(index, 1);
    }

    setProductList(productsData);
  }

  const addProduct = async (product) => {
    if (!product) return false;
    const productData = {
      id: product.id,
      productId: product.id,
      moveQty: 1,
      stockCode: product.barcode,
      productName: product.name,
      image128: product.image128,
      name: product.name,
      barCode: product.barcode,
    };

    let newState = productList;
    const existing = newState.find(
      (element) => element.productId === product.id
    );

    if (!existing) {
      newState.push(productData);
    } else {
      let invComponentsNewState = newState.map((item, i) => {
        if (item.productId === product.id) {
          item.moveQty += 1;
        }
        return item;
      });
      setProductList([...invComponentsNewState]);
      return true;
    }

    // Sort inventory components AFTER adding product
    newState.sort(function (a, b) {
      return new Date(b.barCode) - new Date(a.barCode);
    });

    setProductList([...newState]);
  };

  const Quantity = ({ pack, quantity, index }) => {
    // const isEach = pack == "Each";
    const isMeter = pack === "Metre" || pack === "Feet";

    const quantityMessage = isMeter ? pack : "Quantity";

    return (
      <div className="quantity-form">
        <Grid columns="equal">
          <Grid.Column width={16}>
            <Form.Field>
              <label htmlFor="quantity">{quantityMessage}</label>
              <div style={{ marginBottom: 10 }}>
                <NumericInput
                  style={{
                    input: {
                      padding: 5,
                      textAlign: "center",
                    },
                  }}
                  onChange={(e, data) => handleQuantityChange(data, index)}
                  name="quantity"
                  min={1}
                  max={10000}
                  maxLength={6}
                  className="form-control"
                  precision={0}
                  step={1}
                  value={quantity}
                />
              </div>
            </Form.Field>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      <MetaData title="Transfers" />
      <Modal size="mini" open={deleteStockTransferOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete {formData?.moveName}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteStockTransferOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteStockTransfer()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid
        columns={3}
        divided
        className="stock-transfer"
        style={{ marginTop: -30 }}
      >
        <Grid.Row style={{ paddingTop: 5, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            <h3 style={{ marginLeft: 20 }}>
              <img
                src={inventoryIcon}
                style={{
                  width: 30,
                  height: 30,
                  marginTop: -5,
                  marginRight: 8,
                }}
              />{" "}
              Transfers
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            width={4}
            className="transfer-list-section"
            style={{ paddingRight: 0 }}
          >
            <hr />
            <div
              className="transfer-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 0, paddingBottom: 10 }}
                  >
                    <div>
                      <Button
                        className="active-status"
                        size="small"
                        style={{
                          width: "100%",
                          padding: 11,
                        }}
                        onClick={() => {
                          setFormData({});
                          setProductList([]);
                          setSelectedId(0);
                        }}
                      >
                        New Transfer
                      </Button>
                    </div>
                    <div>
                      <Select
                        style={{
                          fontSize: 13,
                          width: "100%",
                          marginTop: 10,
                        }}
                        options={
                          localStorage.getItem("userType") === "4"
                            ? transferStatusListOperator
                            : transferStatusList
                        }
                        value={status}
                        onChange={(e, data) => setStatus(data.value)}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="transfer-list">
              <div style={{ position: "absolute", top: 300, left: 80 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading Stock Transfers...
                  </Loader>
                ) : null}
              </div>
              <div
                {...events}
                ref={ref}
                className="inventory-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1, paddingTop: 5 }}
              >
                {transfersList?.map((data, index) => {
                  // if (localStorage.getItem("userType") === "4" && index !== 0) {
                  //   return null;
                  // }
                  return (
                    <Card
                      className={
                        selectedId === data?.id ? "job-card active" : "job-card"
                      }
                      style={{
                        cursor: "default",
                        minHeight: 126,
                        minWidth: 185,
                        borderRadius: 10,
                        marginBottom: "0px !important",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                        position: "relative", // Ensure relative positioning
                        cursor: "pointer",
                      }}
                    >
                      <Card.Content
                        style={{
                          minHeight: 60,
                          padding: 10,
                        }}
                        onClick={() => {
                          if (selectedId !== data?.id) {
                            setSelectedId(data?.id);
                            setFormData(data);
                          } else {
                            setSelectedId(null);
                            setProductList([]);
                            setFormData(null);
                          }
                        }}
                      >
                        <Grid style={{ paddingBottom: 15 }}>
                          <Grid.Column
                            width={16}
                            style={{
                              paddingTop: 15,
                              paddingBottom: 5,
                              paddingLeft: 20,
                              paddingRight: 0,
                            }}
                          >
                            <div>
                              <h5>{data?.moveName}</h5>
                            </div>
                            <div
                              style={{
                                paddingTop: 5,
                                paddingLeft: 20,
                                fontSize: 13,
                              }}
                            >
                              <span>
                                From: {getStockLocationName(data?.moveFrom)}
                              </span>
                            </div>
                            <div
                              style={{
                                paddingLeft: 20,
                                fontSize: 13,
                              }}
                            >
                              <span>
                                To: {getStockLocationName(data?.moveTo)}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              Created By:
                            </span>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                              textAlign: "right",
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              {data?.createdBy}
                            </span>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              Created Date:
                            </span>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                              textAlign: "right",
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              {formatDateTime(data?.createdDateTime, false)}
                            </span>
                          </Grid.Column>
                        </Grid>
                      </Card.Content>
                      {data?.statusId === 1 ||
                      data?.statusId === 99 ||
                      localStorage.getItem("userType") === "4" ? null : (
                        <div
                          style={{
                            position: "absolute",
                            top: 40,
                            right: 5,
                            fontSize: 13,
                          }}
                        >
                          <Icon
                            style={{ marginRight: 8, color: "red" }}
                            link
                            name="trash"
                            onClick={() => {
                              setFormData(data);
                              setTimeout(() => {
                                setDeleteStockTransferOpen(true);
                              }, 300);
                            }}
                          />
                        </div>
                      )}
                    </Card>
                  );
                })}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={12} style={{ paddingRight: 0 }}>
            <hr />
            {selectedId === null ? null : (
              <div className="transfer-form">
                <Grid style={{ paddingLeft: 9, paddingTop: 5 }}>
                  <Grid.Column width={6} style={{ paddingRight: 0 }}>
                    <div
                      style={{
                        paddingTop: 7.5,
                        fontSize: 16,
                        fontWeight: "bolder",
                      }}
                    >
                      {formData?.id ? (
                        <span>Stock Transfer - {formData?.moveName}</span>
                      ) : (
                        <span>New Stock Transfer</span>
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    {!formData.statusId || formData.statusId === 0 ? (
                      <div
                        className="job-actions"
                        style={{ textAlign: "left" }}
                      >
                        {localStorage.getItem("userType") === "4" ||
                        formData.statusId !== 0 ? null : (
                          <Button
                            className="active-status"
                            style={{
                              minWidth: 100,
                              marginRight: 10,
                            }}
                            size="small"
                            onClick={() => onCompleteStockTransfer()}
                          >
                            Complete
                          </Button>
                        )}
                        <Button
                          className="default-status"
                          style={{
                            minWidth: 100,
                            marginRight: 10,
                          }}
                          size="small"
                          onClick={() => onSaveStockTransfer()}
                        >
                          Save and close
                        </Button>
                        <Button
                          color="youtube"
                          style={{
                            minWidth: 100,
                          }}
                          size="small"
                          onClick={() => {
                            setFormData({});
                            setProductList([]);
                            setSelectedId(null);
                          }}
                        >
                          Discard
                        </Button>
                      </div>
                    ) : (
                      <div className="job-actions">
                        <Button
                          className="default-status"
                          style={{
                            minWidth: 100,
                            marginRight: 10,
                          }}
                          size="small"
                          onClick={() => {
                            setFormData({});
                            setSelectedId(null);
                            setProductList([]);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    style={{ minHeight: 200 }}
                    disabled={
                      formData.statusId === 1 || formData.statusId === 99
                    }
                  >
                    <Form>
                      <Grid>
                        {formData.statusId === 1 && (
                          <Grid.Column
                            width={16}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            <div style={{ color: "#9F9F9F", fontSize: 14 }}>
                              <span>Completed By: {formData.completedBy}</span>
                              <br />
                              <span>
                                Completed Date Time:{" "}
                                {formatDateTime(
                                  formData.completedDateTime,
                                  true
                                )}
                              </span>
                            </div>
                          </Grid.Column>
                        )}
                        {formData.statusId === 99 && (
                          <Grid.Column
                            width={16}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            <div style={{ color: "#9F9F9F", fontSize: 14 }}>
                              <span>Deleted By: {formData.deletedBy}</span>
                              <br />
                              <span>
                                Deleted Date Time:{" "}
                                {formatDateTime(formData.deletedDateTime, true)}
                              </span>
                            </div>
                          </Grid.Column>
                        )}
                        <Grid.Column width={6}>
                          <Form.Field required style={{ marginBottom: 15 }}>
                            <label>Transfer from</label>
                            <Dropdown
                              search
                              placeholder="Select Stock Location"
                              selection
                              options={stocklocationList}
                              value={formData?.fromStockLocationId}
                              onChange={(e, data) =>
                                handleChange("fromStockLocationId", data.value)
                              }
                            />
                          </Form.Field>
                          <Form.Field required style={{ marginBottom: 30 }}>
                            <label>Transfer to</label>
                            <Dropdown
                              search
                              placeholder="Select Stock Location"
                              selection
                              options={stocklocationList}
                              value={formData?.toStockLocationId}
                              onChange={(e, data) =>
                                handleChange("toStockLocationId", data.value)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column required width={9}>
                          <Form.Field>
                            <label>
                              Reason <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextArea
                              style={{ minHeight: 130 }}
                              onChange={(e, data) =>
                                handleChange("transferReason", data.value)
                              }
                              value={formData?.transferReason}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid>
                    </Form>
                  </Grid.Column>
                </Grid>
                <hr />
                <div>
                  <div style={{ position: "absolute", top: 330, left: 480 }}>
                    <Loader size="massive" active={dataLoading} />
                  </div>
                  <div
                    disabled={
                      formData.statusId === 1 || formData.statusId === 99
                    }
                    className="transfer-form-content"
                    style={{
                      paddingRight: "0px !important",
                      opacity: dataLoading ? 0.5 : 1,
                    }}
                  >
                    <Grid
                      style={{
                        padding: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Grid.Column
                        width={7}
                        style={{
                          textAlign: "left",
                          paddingTop: 30,
                          paddingBottom: 20,
                        }}
                      >
                        <QuickSearch addToCart={addProduct} />
                      </Grid.Column>
                      {productList?.map((card, index) => {
                        return (
                          <Grid.Column
                            width={15}
                            style={{ padding: 0, paddingTop: 25 }}
                            key={card.id}
                          >
                            <Grid>
                              <Grid.Column
                                width={2}
                                style={{
                                  padding: 0,
                                  paddingLeft: 43,
                                  paddingBottom: 20,
                                }}
                              >
                                <Image
                                  style={{
                                    width: 85,
                                    height: 85,
                                  }}
                                  src={
                                    card.image128
                                      ? `data:image/jpeg;base64,${card.image128}`
                                      : productDefaultImage
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column
                                width={11}
                                style={{
                                  paddingTop: 5,
                                  paddingLeft: 20,
                                  paddingBottom: 20,
                                }}
                              >
                                <div>
                                  <span
                                    style={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {card.barCode}
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Stock Description:{" "}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: 14,
                                    }}
                                  >
                                    {card?.productName}
                                  </span>
                                </div>
                                <div>
                                  {formData.statusId === 0 ? (
                                    <span
                                      onClick={() =>
                                        handleQuantityChange(0, index)
                                      }
                                    >
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: 13,
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Icon name="cancel" />
                                        Remove
                                      </span>
                                    </span>
                                  ) : null}
                                </div>
                              </Grid.Column>
                              <Grid.Column width={3}>
                                <Form model="" style={{ textAlign: "center" }}>
                                  <Quantity
                                    pack={null}
                                    quantity={card.moveQty}
                                    index={index}
                                  />
                                </Form>
                              </Grid.Column>
                            </Grid>
                            <hr style={{ marginRight: 0 }} />
                          </Grid.Column>
                        );
                      })}
                    </Grid>
                  </div>
                </div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    userInfo: state.auth.user,
  };
};

const reducer = { ...cartReducer, ...searchReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StockTransfer);
