import axios from "axios";
import { padZero } from "../../utils/utils";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiFetchStockMove = () => {
  const premiumActivatedAt = localStorage.getItem("premiumActivatedAt");
  return axios
    .post(
      `/api/InventoryManagement/fetch-stock-move?premiumActivatedAt=${premiumActivatedAt}`
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        console.log(error.response.data);
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetOrderHistoryList = (search, technician) => {
  const data = {
    textSearch: search,
    technicianEmail: technician === "All" ? "" : technician,
  };
  return axios
    .get(`/api/SalesOrder/order-history-odoo`, { params: data })
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((data) => {
            if (data.dateOrder !== null) {
              var dateObject = new Date(data.dateOrder);

              // Format the date
              var formattedDate = `${padZero(dateObject.getDate())}/${padZero(
                dateObject.getMonth() + 1
              )}/${dateObject.getFullYear().toString().slice(2)}`;
              var hours = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
              var formattedTime = `${padZero(hours)}:${padZero(
                dateObject.getMinutes()
              )} ${dateObject.getHours() < 12 ? "AM" : "PM"}`;
              // Combine date and time
              data.formattedDate = `${formattedDate}, ${formattedTime}`;
            }
          });
          return result.data;
        }
      }
      return false;
    });
};

export const apiGetOrderDetails = (id) => {
  const data = {
    orderId: id,
    timeZone: timeZone,
  };
  return axios
    .get(`/api/SalesOrder/order-details-odoo`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiGetDeliveryDetails = (id) => {
  const data = {
    pickingId: id,
    timeZone: timeZone,
  };
  return axios
    .get(`/api/SalesOrder/delivery-details-odoo`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiGetReturnDetails = (id) => {
  const data = {
    pickingId: id,
    timeZone: timeZone,
  };
  return axios
    .get(`/api/SalesOrder/return-details-odoo`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiConfirmReceipt = (pickingId, stockLocationId) => {
  const data = {
    pickingId: pickingId,
    stockLocationId: stockLocationId,
  };
  return axios
    .post("/api/InventoryManagement/confirm-receipt-in", data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiConfirmReturn = (pickingId, stockLocationId) => {
  const data = {
    pickingId: pickingId,
    stockLocationId: stockLocationId,
  };
  return axios
    .post("/api/InventoryManagement/confirm-receipt-in", data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};
