import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Button,
  Header,
  Modal,
} from "semantic-ui-react";
import "./orderHistory.css";
import {
  apiConfirmReceipt,
  apiConfirmReturn,
  apiFetchStockMove,
  apiGetDeliveryDetails,
  apiGetOrderDetails,
  apiGetOrderHistoryList,
  apiGetReturnDetails,
} from "./orderHistory.api";
import MetaData from "../../components/MetaData";
import { useDraggable } from "react-use-draggable-scroll";
import {
  formatDateTime,
  padZero,
  sortKeysWithDatesFirstDescending,
} from "../../utils/utils";
import { apiGetUsersListWithCurrent } from "../MyAssets/myAssets.api";
import _debounce from "lodash/debounce";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import Skeleton from "react-loading-skeleton";
import { apiGetStockLocationList } from "../Settings/settings.api";

const OrderHistoryNew = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [technicianFilter, setTechnicianFilter] = useState("All");
  const [technicianFilterList, setTechnicianFilterList] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [firstSelectedId, setFirstSelectedId] = useState(0);
  const [technicianName, setTechnicianName] = useState("");
  const [orderHistoryList, setOrderHistoryList] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [formData, setFormData] = useState({});
  const [formProducts, setFormProducts] = useState([]);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [stocklocationList, setStocklocationList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const ref = useRef();
  const { events } = useDraggable(ref);

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(formData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "stockLocationTo") {
          const stockLocation = stocklocationList.find(
            (item) => item.id === value
          );
          item.stockLocationToName = stockLocation?.stockLocationName;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setFormData(data[0]);
  }

  const onSearch = async (search, technician) => {
    setSearchLoading(true);
    const result = await apiGetOrderHistoryList(search, technician);
    if (result) {
      setSearchLoading(false);
      const groupedByDateTime = result.reduce((result, item) => {
        var key = "";
        const date = item.dateOrder ? new Date(item.dateOrder) : "Unconfirmed"; // Use ISO string to ensure consistent keys

        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        if (date !== "Unconfirmed") {
          key = date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          });
        } else {
          key = "Unconfirmed";
        }
        // Check if the key already exists, if not, create an empty array
        result[key] = result[key] || [];
        result[key].push(item);

        return result;
      }, {});

      const orderedKeys = Object.keys(groupedByDateTime).sort((a, b) => {
        // Define the order of keys
        const order = {
          Overdue: -3,
          Today: -2,
          Tomorrow: 1,
          Unconfirmed: 100,
          Completed: 101,
        };

        // Compare keys based on the order defined
        return (
          (order[a] || Number.MAX_SAFE_INTEGER) -
          (order[b] || Number.MAX_SAFE_INTEGER)
        );
      });

      const sortedKeys = sortKeysWithDatesFirstDescending(orderedKeys);

      const orderedGrouped = sortedKeys.reduce((result, key) => {
        result[key] = groupedByDateTime[key];
        return result;
      }, {});

      // Iterate over the keys of the object
      for (const key in orderedGrouped) {
        // Check if the key is not in the excludeKeys array
        orderedGrouped[key].sort((a, b) => {
          if (a.dateOrder === null) return 1; // Null dateOrder goes to the end
          if (b.dateOrder === null) return -1; // Null dateOrder goes to the end
          return new Date(b.dateOrder) - new Date(a.dateOrder);
        });
      }

      console.log(orderedGrouped);
      setOrderHistoryList(orderedGrouped);
    }
  };

  const getUsersList = async () => {
    const result = await apiGetUsersListWithCurrent();
    if (result) {
      const list = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      const filterList = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      filterList.unshift({ text: "All", value: "All" });
      setTechnicianFilterList(filterList);
      setTechnicianFilter("");
    }
  };

  const getOrderData = async () => {
    setDataLoading(true);
    const result = await apiGetOrderDetails(selectedId);
    if (result) {
      setSelectedData(result);
      setDataLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setDataLoading(false);
    }
  };

  const getPickingData = async () => {
    if (isDelivery) {
      getDeliveryData();
    } else {
      getReturnData();
    }
  };

  const getDeliveryData = async () => {
    setFormProducts([]);
    const result = await apiGetDeliveryDetails(formData?.id);
    if (result) {
      setFormProducts(result);
      setIsFormLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsFormLoading(false);
    }
  };

  const getReturnData = async () => {
    setFormProducts([]);
    const result = await apiGetReturnDetails(formData?.id);
    if (result) {
      setFormProducts(result);
      setIsFormLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsFormLoading(false);
    }
  };

  const getStockLocationsData = async () => {
    const result = await apiGetStockLocationList();
    if (result) {
      setStocklocationList(result);
    } else {
      setStocklocationList([]);
    }
  };

  const onConfirm = async () => {
    if (isDelivery) {
      onConfirmReceipt();
    } else {
      onConfirmReturn();
    }
  };

  const onConfirmReceipt = async () => {
    setIsLoading(true);
    const result = await apiConfirmReceipt(
      formData?.id,
      formData?.stockLocationTo
    );
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Receipt in completed successfully
        </div>
      );
      setTimeout(function () {
        setConfirmOpen(false);
        onSearch(searchTerm, technicianFilter);
        getOrderData();
        handleChange("isReceiptIn", true);
        setIsLoading(false);
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onConfirmReturn = async () => {
    setIsLoading(true);
    const result = await apiConfirmReturn(
      formData?.id,
      formData?.stockLocationFrom
    );
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Return confirmed successfully
        </div>
      );
      setTimeout(function () {
        setConfirmOpen(false);
        onSearch(searchTerm, technicianFilter);
        getOrderData();
        handleChange("isConfirmed", true);
        setIsLoading(false);
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const debouncedHandleChange = _debounce((value) => {
    onSearch(value, technicianFilter);
    setSearchTerm(value);
  }, 500);

  const handleSearchChange = (event) => {
    const { value } = event.target;

    // Call the debounced function with the input value
    debouncedHandleChange(value);
  };

  const addProtocolIfNeeded = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return "http://" + url;
    }
    return url;
  };

  useEffect(() => {
    if (formData?.id) {
      if (stocklocationList.length === 0) getStockLocationsData();
      setIsFormLoading(true);
      setTimeout(() => {
        getPickingData();
      }, 500);
    }
  }, [formData?.id]);

  useEffect(() => {
    if (technicianFilterList.length > 0) {
      onSearch(searchTerm, technicianFilter);
      var technician = technicianFilterList.find(function (element) {
        return element.value === technicianFilter;
      });
      if (technician) setTechnicianName(technician.text);
    }
  }, [technicianFilter]);

  useEffect(() => {
    if (selectedId !== 0) {
      getOrderData();
    } else {
      setSelectedData({});
    }
  }, [selectedCard]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      apiFetchStockMove();
      getUsersList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const orderListGrouped = Object.entries(orderHistoryList).map(
    ([category, list], index) => (
      <div style={{ marginTop: index === 0 ? 0 : 15 }}>
        <div style={{ marginBottom: 5 }}>
          <span
            style={{
              fontSize: 13,
              marginLeft: 0,
              fontWeight: "bolder",
            }}
          >
            {category}
          </span>
        </div>
        {list.map((data, i) => {
          if (index === 0 && i === 0 && firstSelectedId === 0) {
            setFirstSelectedId(data.Id);
            setSelectedId(data.id);
            setSelectedCard(data);
          }
          var date = "";
          if (data.dateOrder !== null) {
            var dateObject = new Date(data.dateOrder);

            // Format the date
            var formattedDate = `${padZero(dateObject.getDate())}/${padZero(
              dateObject.getMonth() + 1
            )}/${dateObject.getFullYear().toString().slice(2)}`;
            var hours = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
            var formattedTime = `${padZero(hours)}:${padZero(
              dateObject.getMinutes()
            )} ${dateObject.getHours() < 12 ? "AM" : "PM"}`;
            // Combine date and time
            date = `${formattedDate}`;
          }
          return (
            <Card
              className={
                selectedId === data.id ? "order-card active" : "order-card"
              }
            >
              <Card.Content
                onClick={() => {
                  if (selectedId !== data.id) {
                    setFormProducts([]);
                    setFormData({});
                    setSelectedData({});
                    setSelectedId(data.id);
                    setSelectedCard(data);
                  } else {
                    setSelectedId(0);
                    setFormProducts([]);
                    setFormData({});
                    setSelectedCard({});
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid columns={2} className="order-management">
                  <Grid.Row>
                    <Grid.Column
                      width={16}
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                    >
                      <b>
                        <span style={{ fontSize: 17 }}>
                          {data.clientOrderRef}
                        </span>
                      </b>
                      <Card.Description style={{ marginTop: 7 }}>
                        <Icon
                          name="user"
                          style={{ color: "#66cc23", marginRight: 10 }}
                        />
                        {data.orderedByName}
                      </Card.Description>
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: 13,
                          marginTop: -20,
                        }}
                      >
                        <span>{formattedTime}</span>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          );
        })}
      </div>
    )
  );

  return (
    <React.Fragment>
      <MetaData title="Order History" />
      <Modal size="mini" open={confirmOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          {isDelivery ? (
            <p>
              Are you sure you want to <br />
              receipt these products into {formData?.stockLocationToName}?
            </p>
          ) : (
            <p>
              Are you sure you want to <br />
              confirm this return into {formData?.stockLocationToName}?
            </p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setConfirmOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onConfirm(true)}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid
        columns={3}
        divided
        className="order-management"
        style={{ marginTop: -30 }}
      >
        <Grid.Row style={{ paddingTop: 0, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            {selectedCard?.clientOrderRef ? (
              <h3 style={{ marginLeft: 20 }}>
                <Icon name="configure" /> Order History -{" "}
                {selectedCard?.clientOrderRef}
              </h3>
            ) : (
              <h3 style={{ marginLeft: 20 }}>
                <Icon name="configure" /> Order History
              </h3>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            computer={4}
            tablet={6}
            mobile={16}
            className="order-list-section"
            style={{ paddingRight: 0 }}
          >
            <div className="order-header">
              <Input
                icon="search"
                placeholder="Search for your order..."
                style={{ width: "100%" }}
                size="small"
                onChange={handleSearchChange}
              />
            </div>
            <div
              className="order-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 8, paddingBottom: 10 }}
                  >
                    <Dropdown
                      style={{ fontSize: 13, width: "100%" }}
                      basic
                      color="black"
                      text={
                        technicianFilter
                          ? technicianName
                          : "Filter by technician"
                      }
                      search
                      scrolling
                      icon="user"
                      floating
                      labeled
                      button
                      className="icon"
                      options={technicianFilterList}
                      onChange={(e, data) => {
                        setTechnicianFilter(data.value);
                      }}
                      value={technicianFilter}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="order-list">
              <div style={{ position: "absolute", top: 300, left: 80 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading Order History...
                  </Loader>
                ) : null}
              </div>
              <div
                {...events}
                ref={ref}
                className="order-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1 }}
              >
                {orderListGrouped}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={8} style={{ paddingRight: 0 }}>
            <div className="order-form">
              {selectedData?.orderName ? (
                <div
                  className="order-form-content"
                  style={{ paddingRight: "0px !important" }}
                >
                  <Form size="tiny">
                    <Grid>
                      <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: 5,
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{ paddingTop: 5, paddingBottom: 0 }}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Details - {selectedData?.orderName}
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                          }}
                        >
                          <hr />
                        </Grid.Column>
                        <Grid.Column
                          computer={6}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                            paddingLeft: 45,
                          }}
                        >
                          <Form.Field>
                            <label style={{ color: "#66cc23" }}>
                              Order Number
                            </label>
                            <span>{selectedCard?.clientOrderRef}</span>
                          </Form.Field>
                          <Form.Field style={{ marginTop: "1.5rem" }}>
                            <label style={{ color: "#66cc23" }}>
                              Order Date & Time
                            </label>
                            <span>{selectedCard?.formattedDate}</span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          computer={10}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                          }}
                        >
                          <Form.Field>
                            <label style={{ color: "#66cc23" }}>
                              Delivery Address
                            </label>
                            <span>{selectedData?.deliveryAddress}</span>
                          </Form.Field>
                          <Form.Field style={{ marginTop: "1.5rem" }}>
                            <label style={{ color: "#66cc23" }}>Notes</label>
                            <span>{selectedData?.notes}</span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -20,
                            paddingLeft: 45,
                          }}
                        >
                          <Form.Field style={{ marginTop: "1.5rem" }}>
                            <label style={{ color: "#66cc23" }}>
                              Ordered by
                            </label>
                            {selectedCard?.orderedBy ? (
                              <span>
                                {selectedCard?.orderedByName},{" "}
                                {selectedCard?.orderedBy}
                              </span>
                            ) : (
                              <span>Phone Call Order</span>
                            )}
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{ paddingTop: "1rem", paddingBottom: 0 }}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Deliveries
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                          }}
                        >
                          <hr />
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 5,
                            paddingBottom: 0,
                            marginTop: -10,
                            paddingLeft: 45,
                          }}
                        >
                          <Grid
                            style={{
                              margin: 0,
                              marginBottom: 10,
                              minHeight:
                                selectedData?.deliveries?.length > 0 ? 90 : 0,
                            }}
                          >
                            {selectedData?.deliveries?.map((delivery) => {
                              return (
                                <Grid.Column
                                  width={6}
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    paddingRight: 15,
                                    paddingBottom: 20,
                                  }}
                                >
                                  <Card
                                    className={
                                      formData?.id === delivery.id
                                        ? "job-card active"
                                        : "job-card"
                                    }
                                    style={{
                                      cursor: "default",
                                      minHeight: 65,
                                      minWidth: 185,
                                      borderRadius: 10,
                                      marginBottom: "0px !important",
                                      boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                                      position: "relative",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Card.Content
                                      style={{
                                        minHeight: 60,
                                        padding: "12px 15px 12px 5px",
                                      }}
                                      onClick={() => {
                                        if (formData?.id !== delivery.id) {
                                          setFormProducts([]);
                                          setIsDelivery(true);
                                          setFormData(delivery);
                                        } else {
                                          setFormData({});
                                          setFormProducts([]);
                                        }
                                      }}
                                    >
                                      <Grid>
                                        <Grid.Column
                                          width={8}
                                          style={{
                                            paddingTop: 16,
                                            paddingBottom: 10,
                                            paddingLeft: 20,
                                            paddingRight: 0,
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontSize: 11,
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            {delivery.name}
                                          </div>
                                        </Grid.Column>
                                        <Grid.Column
                                          width={8}
                                          style={{
                                            paddingTop: 16,
                                            paddingBottom: 10,
                                            paddingLeft: 15,
                                            paddingRight: 10,
                                          }}
                                        >
                                          {localStorage.getItem(
                                            "isBoahubPremium"
                                          ) === "true" &&
                                          delivery?.mappedState ===
                                            "Shipped" ? (
                                            <div
                                              className={
                                                !delivery.isReceiptIn
                                                  ? "edit-state"
                                                  : ""
                                              }
                                              style={{
                                                width: "100%",
                                                float: "right",
                                                fontSize: 12,
                                                fontWeight: "bolder",
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                textAlign: !delivery.isReceiptIn
                                                  ? "center"
                                                  : "right",
                                                color: !delivery.isReceiptIn
                                                  ? "#ffffff"
                                                  : "#66cc23",
                                              }}
                                            >
                                              {delivery.isReceiptIn
                                                ? "Received"
                                                : "Receipt In"}
                                            </div>
                                          ) : null}
                                        </Grid.Column>
                                        <Grid.Column
                                          width={9}
                                          style={{
                                            paddingTop: 0,
                                            paddingLeft: 20,
                                            paddingRight: 0,
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontSize: 12,
                                              color:
                                                delivery?.mappedState ===
                                                "Shipped"
                                                  ? "#66CC23"
                                                  : "#FFAD00",
                                            }}
                                          >
                                            {delivery.mappedState}
                                          </div>
                                        </Grid.Column>
                                        <Grid.Column
                                          width={7}
                                          style={{
                                            paddingTop: 0,
                                            paddingLeft: 20,
                                            paddingRight: 12,
                                            textAlign: "right",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontSize: 12,
                                            }}
                                          >
                                            {formatDateTime(
                                              delivery.scheduledDate,
                                              false
                                            )}
                                          </div>
                                        </Grid.Column>
                                      </Grid>
                                    </Card.Content>
                                  </Card>
                                </Grid.Column>
                              );
                            })}
                          </Grid>
                        </Grid.Column>
                        {selectedData?.returns?.length > 0 && (
                          <>
                            <Grid.Column
                              computer={1}
                              tablet={2}
                              mobile={2}
                              style={{
                                paddingTop: "1rem",
                                paddingBottom: "1rem",
                                paddingRight: 0,
                              }}
                            >
                              <Image
                                width={18}
                                src={rightArrow}
                                wrapped
                                style={{ marginTop: -8.5 }}
                              />
                            </Grid.Column>
                            <Grid.Column
                              computer={15}
                              tablet={14}
                              mobile={14}
                              style={{ paddingTop: "1rem", paddingBottom: 0 }}
                            >
                              <Header as="h5" style={{ marginLeft: -15 }}>
                                Returns
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              computer={16}
                              tablet={14}
                              mobile={14}
                              style={{
                                paddingTop: 0,
                                paddingBottom: "1rem",
                                marginTop: -10,
                              }}
                            >
                              <hr />
                            </Grid.Column>
                            <Grid.Column
                              computer={16}
                              tablet={14}
                              mobile={14}
                              style={{
                                paddingTop: 5,
                                paddingBottom: 0,
                                marginTop: -10,
                                paddingLeft: 45,
                              }}
                            >
                              <Grid
                                style={{
                                  margin: 0,
                                  minHeight:
                                    selectedData?.returns?.length > 0 ? 90 : 0,
                                }}
                              >
                                {selectedData?.returns?.map((returnData) => {
                                  return (
                                    <Grid.Column
                                      width={6}
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        paddingRight: 15,
                                        paddingBottom: 20,
                                      }}
                                    >
                                      <Card
                                        className={
                                          formData?.id === returnData.id
                                            ? "job-card active"
                                            : "job-card"
                                        }
                                        style={{
                                          cursor: "default",
                                          minHeight: 65,
                                          maxHeight: 60,
                                          minWidth: 185,
                                          borderRadius: 10,
                                          marginBottom: "0px !important",
                                          boxShadow:
                                            "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                                          position: "relative",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Card.Content
                                          style={{
                                            minHeight: 60,
                                            padding: "12px 15px 12px 5px",
                                          }}
                                          onClick={() => {
                                            if (
                                              formData?.id !== returnData.id
                                            ) {
                                              setFormProducts([]);
                                              setIsDelivery(false);
                                              setFormData(returnData);
                                            } else {
                                              setFormData({});
                                              setFormProducts([]);
                                            }
                                          }}
                                        >
                                          <Grid>
                                            <Grid.Column
                                              width={8}
                                              style={{
                                                paddingTop: 16,
                                                paddingBottom: 10,
                                                paddingLeft: 20,
                                                paddingRight: 0,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                {returnData.name}
                                              </div>
                                            </Grid.Column>
                                            <Grid.Column
                                              width={8}
                                              style={{
                                                paddingTop: 16,
                                                paddingBottom: 10,
                                                paddingLeft: 15,
                                                paddingRight: 10,
                                              }}
                                            >
                                              <div
                                                className={
                                                  !returnData.isConfirmed
                                                    ? "edit-state"
                                                    : ""
                                                }
                                                style={{
                                                  width: "100%",
                                                  float: "right",
                                                  fontSize: 12,
                                                  fontWeight: "bolder",
                                                  paddingLeft: 6,
                                                  paddingRight: 6,
                                                  textAlign:
                                                    !returnData.isConfirmed
                                                      ? "center"
                                                      : "right",
                                                  color: !returnData.isConfirmed
                                                    ? "#ffffff"
                                                    : "#66cc23",
                                                }}
                                              >
                                                {returnData.isConfirmed
                                                  ? "Confirmed"
                                                  : "To Confirm"}
                                              </div>
                                            </Grid.Column>
                                            <Grid.Column
                                              width={8}
                                              style={{
                                                paddingTop: 0,
                                                paddingLeft: 20,
                                                paddingRight: 0,
                                              }}
                                            ></Grid.Column>
                                            <Grid.Column
                                              width={8}
                                              style={{
                                                paddingTop: 0,
                                                paddingLeft: 20,
                                                paddingRight: 12,
                                                textAlign: "right",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                }}
                                              >
                                                {formatDateTime(
                                                  returnData.createDate,
                                                  false
                                                )}
                                              </div>
                                            </Grid.Column>
                                          </Grid>
                                        </Card.Content>
                                      </Card>
                                    </Grid.Column>
                                  );
                                })}
                              </Grid>
                            </Grid.Column>
                          </>
                        )}
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{ paddingTop: "1rem", paddingBottom: 0 }}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Products
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                          }}
                        >
                          <hr />
                        </Grid.Column>
                        <Grid.Column
                          computer={10}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: -10,
                            paddingLeft: 45,
                          }}
                        >
                          {selectedData?.products.map((data) => {
                            return (
                              <Form.Field style={{ marginBottom: 20 }}>
                                <label style={{ fontSize: 16 }}>
                                  {data?.productName}
                                </label>
                                <Grid>
                                  <Grid.Column width={8}>
                                    <span style={{ color: "#66cc23" }}>
                                      SKU: {data?.productSku}
                                    </span>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    <span>
                                      QTY: {data?.qty}{" "}
                                      {data?.productUOMName.toUpperCase()}
                                    </span>
                                  </Grid.Column>
                                </Grid>
                                <hr style={{ marginRight: 0 }} />
                              </Form.Field>
                            );
                          })}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </div>
              ) : dataLoading ? (
                <Skeleton count={30} />
              ) : null}
            </div>
          </Grid.Column>
          <Grid.Column width={4} style={{ paddingRight: 0 }}>
            <div style={{ position: "absolute", top: 150, left: 120 }}>
              {isFormLoading ? (
                <Loader
                  active
                  className="workaround"
                  size="large"
                  inline="centered"
                />
              ) : null}
            </div>
            <div className="order-info">
              {formData?.id && formProducts.length > 0 ? (
                <Grid>
                  <Grid.Row style={{ paddingBottom: 0 }}>
                    <Grid.Column
                      width={2}
                      style={{
                        paddingTop: 5,
                        paddingBottom: 12.5,
                        paddingRight: 0,
                      }}
                    >
                      <Image
                        width={18}
                        src={rightArrow}
                        wrapped
                        style={{ marginTop: -8.5 }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={14}
                      style={{ paddingTop: 5, paddingBottom: 0 }}
                    >
                      <Header as="h5" style={{ marginLeft: -15 }}>
                        {`${
                          localStorage.getItem("isBoahubPremium") !== "true"
                            ? ""
                            : isDelivery
                            ? "Receipt In"
                            : "Return"
                        } ${formData?.name}`}
                      </Header>
                    </Grid.Column>
                    <Grid.Column
                      computer={16}
                      tablet={14}
                      mobile={14}
                      style={{
                        paddingTop: 0,
                        paddingBottom: "1rem",
                        marginTop: -10,
                        textAlign: "right",
                      }}
                    >
                      {formData?.courierTrackingNumber && (
                        <a
                          style={{ cursor: "pointer" }}
                          href={addProtocolIfNeeded(
                            formData?.courierTrackingNumber
                          )}
                          target="_blank"
                        >
                          Track Shipment
                        </a>
                      )}
                    </Grid.Column>
                    <Grid.Column
                      computer={16}
                      tablet={14}
                      mobile={14}
                      style={{
                        paddingTop: 0,
                        paddingBottom: "1rem",
                        marginTop: -10,
                      }}
                    >
                      <hr />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Form>
                        {(isDelivery &&
                          localStorage.getItem("isBoahubPremium") !== "true") ||
                        formData?.state === "Ready" ? null : isDelivery ? (
                          <Form.Field style={{ marginBottom: 30 }}>
                            <label>Stock Location</label>
                            <Dropdown
                              search
                              placeholder="Stock Location"
                              selection
                              options={stocklocationList}
                              value={formData?.stockLocationTo}
                              onChange={(e, data) =>
                                handleChange("stockLocationTo", data.value)
                              }
                              style={{
                                pointerEvents: formData?.isReceiptIn
                                  ? "none"
                                  : "initial",
                                cursor: formData?.isReceiptIn
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            />
                          </Form.Field>
                        ) : (
                          <Form.Field style={{ marginBottom: 30 }}>
                            <label>Stock Location</label>
                            <Dropdown
                              search
                              placeholder="Stock Location"
                              selection
                              options={stocklocationList}
                              value={formData?.stockLocationFrom}
                              onChange={(e, data) =>
                                handleChange("stockLocationFrom", data.value)
                              }
                              style={{
                                pointerEvents: formData?.isConfirmed
                                  ? "none"
                                  : "initial",
                                cursor: formData?.isConfirmed
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            />
                          </Form.Field>
                        )}
                        <Form.Field>
                          {formProducts?.map((data) => {
                            return (
                              <Form.Field style={{ marginBottom: 20 }}>
                                <label style={{ fontSize: 14 }}>
                                  {data?.productName}
                                </label>
                                <Grid>
                                  <Grid.Column width={8}>
                                    <span
                                      style={{ color: "#66cc23", fontSize: 14 }}
                                    >
                                      SKU: {data?.productSku}
                                    </span>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ textAlign: "right", fontSize: 14 }}
                                  >
                                    <span>
                                      QTY:{" "}
                                      {formData?.state === "Done"
                                        ? data?.qtyDone
                                        : data?.productQty}{" "}
                                      {data?.productUOMName.toUpperCase()}
                                    </span>
                                  </Grid.Column>
                                </Grid>
                                <hr style={{ marginRight: 0 }} />
                              </Form.Field>
                            );
                          })}
                        </Form.Field>
                        {isDelivery ? (
                          <Form.Field style={{ textAlign: "right" }}>
                            {localStorage.getItem("isBoahubPremium") ===
                              "true" && formData?.mappedState === "Shipped" ? (
                              <Button
                                className={
                                  formData?.isReceiptIn ? "" : "active-status"
                                }
                                size="small"
                                style={{
                                  width: "50%",
                                  marginTop: 7,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  marginRight: 0,
                                  pointerEvents: formData?.isReceiptIn
                                    ? "none"
                                    : "initial",
                                  cursor: formData?.isReceiptIn
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                onClick={() => {
                                  if (!formData?.stockLocationTo) {
                                    showErrorToast("Select Stock Location");
                                  } else {
                                    setConfirmOpen(true);
                                  }
                                }}
                              >
                                {formData?.isReceiptIn
                                  ? "Receipted In"
                                  : "Receipt In"}
                              </Button>
                            ) : null}
                          </Form.Field>
                        ) : (
                          <Form.Field style={{ textAlign: "right" }}>
                            <Button
                              className={
                                formData?.isConfirmed ? "" : "active-status"
                              }
                              size="small"
                              style={{
                                width: "50%",
                                marginTop: 7,
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: 0,
                                pointerEvents: formData?.isConfirmed
                                  ? "none"
                                  : "initial",
                                cursor: formData?.isConfirmed
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              onClick={() => {
                                if (!formData?.stockLocationFrom) {
                                  showErrorToast("Select Stock Location");
                                } else {
                                  setConfirmOpen(true);
                                }
                              }}
                            >
                              {formData?.isConfirmed ? "Confirmed" : "Confirm"}
                            </Button>
                          </Form.Field>
                        )}
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : null}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default OrderHistoryNew;
