import { apiFetchStockMove } from "../OrderHistoryNew/orderHistory.api";
import {
  apiLogin,
  apiGetUserInfo,
  apiSignUp,
  apiLogout,
  apiVerifyEmailCode,
  apiForgotPassSendEmail,
  apiVerifyResetPassCode,
  apiResetPassword,
  apiResendAccountVerificationEmail,
  apiGetProfileDetails,
  apiUpdateProfileInfo,
  apiUpdateAccountInfo,
  apiChangePassword,
  apiVerifyNewEmailCode,
  apiResendVerifyNewEmailLink,
  apiUpdatePriceMargin,
  refreshToken,
  refreshTokenAndReload,
} from "./auth.api";
import { actions } from "react-redux-form";

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const SIGNUP = "SIGNUP";
const LOGOUT = "LOGOUT";
const VERIFY = "VERIFY";
const VERIFY_FAILED = "VERIFY_FAILED";
const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
const SET_VERIFY_FLAG = "SET_VERIFY_FLAG";
const RESET_PASSWORD = "RESET_PASSWORD";
const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
const RESEND_ACCOUNT_VERIFY_EMAIL = "RESEND_ACCOUNT_VERIFY_EMAIL";
const SET_VERIFY_FLAG_FAIL = "SET_VERIFY_FLAG_FAIL";
const SET_PROFILE_EDIT_FLAG = "SET_PROFILE_EDIT_FLAG";
const SET_PROFILE_INFO_DATA = "SET_PROFILE_INFO_DATA";
const UPDATE_PROFILE_INFO = "UPDATE_PROFILE_INFO";
const SET_ACCOUNT_INFO_DATA = "SET_ACCOUNT_INFO_DATA";
const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";
const SET_ACCOUNT_EDIT_FLAG = "SET_ACCOUNT_EDIT_FLAG";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const RESEND_NEW_EMAIL_VERIFICATION_LINK = "RESEND_NEW_EMAIL_VERIFICATION_LINK";
const SET_PRICE_MARGIN = "SET_PRICE_MARGIN";

//const user = window.localStorage.getItem("user");
const initialState = {
  response: {},
  isLoggedIn: !!window.localStorage.getItem("sessionID"),
  user: {},
  verified: false,
  resetPassword: false,
  userDetail: {},
  editProfileFlag: false,
  profileInfo: {},
  accountInfo: {},
  editAccountFlag: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: false,
      };
    case SIGNUP:
      return {
        ...state,
        response: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        response: {},
        isLoggedIn: false,
      };
    case VERIFY:
      return {
        ...state,
        response: action.payload,
      };
    case VERIFY_FAILED:
      return { ...state, response: action.payload };
    case FORGOT_PASSWORD:
      return {
        ...state,
        response: action.payload,
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        response: action.payload,
      };
    case SET_VERIFY_FLAG:
      return {
        ...state,
        verified: action.payload.status === 200 ? true : false,
      };
    case SET_VERIFY_FLAG_FAIL:
      return {
        ...state,
        verified: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.payload.status === 200 ? true : false,
        response: action.payload,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        response: action.payload,
        resetPassword: false,
      };
    case RESEND_ACCOUNT_VERIFY_EMAIL:
      return {
        ...state,
        response: action.payload,
      };
    case SET_PROFILE_EDIT_FLAG:
      return {
        ...state,
        editProfileFlag: action.payload,
      };
    case SET_ACCOUNT_EDIT_FLAG:
      return {
        ...state,
        editAccountFlag: action.payload,
      };
    case SET_PROFILE_INFO_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_PROFILE_INFO:
      return {
        ...state,
        response: action.payload.status,
      };
    case SET_ACCOUNT_INFO_DATA:
      return {
        ...state,
        accountInfo: action.payload,
      };
    case UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        response: action.payload.status,
        accountInfo: action.payload.data,
      };
    case SET_PRICE_MARGIN:
      return {
        ...state,
        response: action.payload.status,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        response: action.payload,
      };
    case RESEND_NEW_EMAIL_VERIFICATION_LINK:
      return {
        ...state,
        response: action.payload,
      };
    default:
      return state;
  }
};

export const actionResetState = (formName) => {
  return (dispatch) => {
    dispatch(actions.reset(formName));
  };
};

export const submitForm = (formName) => {
  return (dispatch) => {
    dispatch(actions.submit("forms.createAccountForm", actionSignUp));
  };
};

export const setEmailToResetForm = (form) => {
  return (dispatch) => {
    dispatch(actions.change("forms.resetPasswordForm", form));
  };
};

export const actionSetChangePasswordForm = (form) => {
  return (dispatch) => {
    dispatch(actions.change("forms.changePasswordForm", form));
  };
};

export const actionSetProfileInfoForm = (form) => {
  return (dispatch) => {
    dispatch(actions.change("forms.profileInfoForm", form));
  };
};

export const actionSetInitialForm = (user) => {
  return (dispatch) => {
    dispatch(actions.change("forms.createAccountForm", user));
  };
};

export const actionSetFormValue = (data) => {
  return (dispatch) => {
    dispatch(actions.change("forms.createAccountForm", data));
  };
};

export const actionlogIn = (LoginData) => {
  return async (dispatch) => {
    try {
      const result = await apiLogin(LoginData);

      if (result.statusCode >= 400) {
        dispatch({
          type: LOGIN_FAILED,
          payload: result,
        });
        return result;
      }

      const { data } = result;
      const userInfoResult = await apiGetUserInfo();
      apiFetchStockMove();
      const user = { user: { ...userInfoResult, isMigrated: data.isMigrated } };

      const {
        accessToken: sessionID,
        accessJobbing,
        refeshToken,
        accountType,
        userType,
        stockLocationName,
        boahubLocationId,
        relatedCompanyLogoUrl,
        relatedCompanyId,
        defaultAssetCategory,
        defaultTechnicianFilter,
        xeroAuthUrl,
        isXeroConnected,
        countryCode,
        countries,
        xeroTenantName,
        boahubFindPod,
        boahubIsRS23,
        isBoahubPremium,
        boahubBuildDigitalTwin,
        premiumActivatedAt,
      } = result.data;

      let countryList = [];
      countries.map((country) => {
        countryList.push(country.code.toLowerCase());
      });

      window.localStorage.setItem("sessionID", sessionID);
      window.localStorage.setItem("accessJobbing", accessJobbing);
      window.localStorage.setItem("boahubFindPod", boahubFindPod);
      window.localStorage.setItem("user", user.user.email);
      window.localStorage.setItem("userName", user.user.firstName);
      window.localStorage.setItem("isMigrated", user.user.isMigrated);
      window.localStorage.setItem("refreshToken", refeshToken);
      window.localStorage.setItem("accountType", accountType);
      window.localStorage.setItem("userType", userType);
      window.localStorage.setItem("stockLocationName", stockLocationName);
      window.localStorage.setItem("boahubLocationId", boahubLocationId);
      window.localStorage.setItem(
        "relatedCompanyLogoUrl",
        relatedCompanyLogoUrl
      );
      window.localStorage.setItem("companyId", relatedCompanyId);
      window.localStorage.setItem("companyName", user.user.parent?.name);
      window.localStorage.setItem("defaultAssetCategory", defaultAssetCategory);
      window.localStorage.setItem(
        "defaultTechnicianFilter",
        defaultTechnicianFilter
      );
      window.localStorage.setItem("xeroAuthUrl", xeroAuthUrl);
      window.localStorage.setItem("isXeroConnected", isXeroConnected);
      window.localStorage.setItem("userCountryCode", countryCode);
      window.localStorage.setItem("countries", JSON.stringify(countryList));
      window.localStorage.setItem("xeroTenantName", xeroTenantName);
      window.localStorage.setItem("boahubIsRS23", boahubIsRS23);
      window.localStorage.setItem("isBoahubPremium", isBoahubPremium);
      window.localStorage.setItem(
        "boahubBuildDigitalTwin",
        boahubBuildDigitalTwin
      );
      window.localStorage.setItem("premiumActivatedAt", premiumActivatedAt);

      if (accountType === 1 || accountType === "1") {
        window.localStorage.setItem("accessJobbing", "true");
      } else {
        window.localStorage.setItem("accessJobbing", "false");
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: user,
      });

      dispatch({
        type: SET_ACCOUNT_INFO_DATA,
        payload: userInfoResult,
      });

      const intendedUrl = window.localStorage.getItem("intendedUrl");
      window.localStorage.removeItem("intendedUrl");

      // if (intendedUrl && intendedUrl.indexOf("/error") === -1) {
      //   document.location.href = intendedUrl;
      // }
    } catch (e) {
      if (e?.response?.data) {
        e.response.description = e.response.data.description;
      }
      dispatch({
        type: LOGIN_FAILED,
        payload: e.response,
      });
    }
  };
};
export const actionSignUp = (newUser) => {
  return async (dispatch) => {
    try {
      const result = await apiSignUp(newUser);
      dispatch({
        type: SIGNUP,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: SIGNUP,
        payload: e.response,
      });
    }
  };
};

export const logout = (user) => {
  const { _id, adminSessionId } = user;
  return async (dispatch) => {
    await apiLogout(_id, adminSessionId);
    localStorage.clear();
    window.location.href = "/";
    dispatch({
      type: LOGOUT,
      payload: {},
    });
  };
};

export const clearAuth = () => {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
      payload: {},
    });
  };
};

export const actionVerifyEmailCode = (code) => {
  return async (dispatch) => {
    try {
      let result = await apiVerifyEmailCode(code);
      dispatch({ type: VERIFY, payload: result });
    } catch (e) {
      dispatch({ type: VERIFY_FAILED, payload: e.response });
    }
  };
};

export const actionSendMail = (params) => {
  return async (dispatch) => {
    try {
      let result = await apiForgotPassSendEmail(params);
      dispatch({ type: FORGOT_PASSWORD, payload: result });
    } catch (e) {
      dispatch({ type: FORGOT_PASSWORD_FAILED, payload: e.response });
    }
  };
};

export const actionVerifyResetPasswordCode = (code, email) => {
  return async (dispatch) => {
    try {
      let result = await apiVerifyResetPassCode(code, email);
      dispatch({ type: SET_VERIFY_FLAG, payload: result });
    } catch (e) {
      dispatch({ type: SET_VERIFY_FLAG_FAIL, payload: e });
    }
  };
};

export const actionResetPassword = (data) => {
  return async (dispatch) => {
    try {
      let result = await apiResetPassword(data);
      dispatch({ type: RESET_PASSWORD, payload: result });
    } catch (e) {
      dispatch({ type: RESET_PASSWORD_FAILED, payload: e });
    }
  };
};

export const actionResendAccountVerificationEmail = (email) => {
  return async (dispatch) => {
    try {
      let result = await apiResendAccountVerificationEmail(email);
      dispatch({ type: RESEND_ACCOUNT_VERIFY_EMAIL, payload: result });
    } catch (e) {
      dispatch({ type: RESEND_ACCOUNT_VERIFY_EMAIL, payload: e });
    }
  };
};

export const actionSetEditProfileFlag = (flag) => {
  return async (dispatch) => {
    dispatch({ type: SET_PROFILE_EDIT_FLAG, payload: flag });
  };
};

export const actionSetEditAccountFlag = (flag) => {
  return async (dispatch) => {
    dispatch({ type: SET_ACCOUNT_EDIT_FLAG, payload: flag });
  };
};

export const actionGetProfileData = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetProfileDetails();
      const { data } = result;
      const { account = {} } = data;
      const { showFacilities } = account;
      window.showFacilities = showFacilities;

      dispatch({
        type: SET_PROFILE_INFO_DATA,
        payload: { loading: false, ...result.data },
      });
      dispatch({
        type: SET_ACCOUNT_INFO_DATA,
        payload: { loading: false, ...result.data },
      });
    } catch (e) {
      dispatch({ type: SET_PROFILE_INFO_DATA, payload: e });
    }
  };
};

export const actionUpdateProfileInfo = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiUpdateProfileInfo(data);
      refreshTokenAndReload();
      dispatch({
        type: UPDATE_PROFILE_INFO,
        payload: { loading: false, ...result },
      });
    } catch (e) {
      const { response } = e;
      dispatch({
        type: UPDATE_PROFILE_INFO,
        payload: { loading: false, ...response },
      });
    }
  };
};

export const actionGetAccountDetails = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetUserInfo();
      dispatch({
        type: SET_PROFILE_INFO_DATA,
        payload: { loading: false, ...result },
      });
      dispatch({
        type: SET_ACCOUNT_INFO_DATA,
        payload: { loading: false, ...result },
      });
    } catch (e) {
      dispatch({ type: SET_ACCOUNT_INFO_DATA, payload: e });
    }
  };
};

export const actionUpdateAccountInfo = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiUpdateAccountInfo(data);
      dispatch({ type: UPDATE_ACCOUNT_INFO, payload: result });
    } catch (e) {
      dispatch({ type: UPDATE_ACCOUNT_INFO, payload: e });
    }
  };
};

export const actionUpdatePriceMargin = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiUpdatePriceMargin(data);
      dispatch({ type: SET_PRICE_MARGIN, payload: result });
    } catch (e) {
      dispatch({ type: SET_PRICE_MARGIN, payload: e });
    }
  };
};

export const actionChangePassword = (data) => {
  return async (dispatch) => {
    try {
      let result = await apiChangePassword(data);
      dispatch({ type: CHANGE_PASSWORD, payload: result });
    } catch (e) {
      dispatch({ type: CHANGE_PASSWORD, payload: e });
    }
  };
};

export const actionVerifyNewEmailCode = (code, email) => {
  return async (dispatch) => {
    try {
      let result = await apiVerifyNewEmailCode(code, email);
      dispatch({ type: VERIFY, payload: result });
    } catch (e) {
      dispatch({ type: VERIFY_FAILED, payload: e.response });
    }
  };
};

export const actionResendVerifyNewEmailLink = () => {
  return async (dispatch) => {
    try {
      let result = await apiResendVerifyNewEmailLink();
      dispatch({ type: RESEND_NEW_EMAIL_VERIFICATION_LINK, payload: result });
    } catch (e) {
      dispatch({
        type: RESEND_NEW_EMAIL_VERIFICATION_LINK,
        payload: e.response,
      });
    }
  };
};
